import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";

export const useUtils = () => {
  const { t } = useTranslation();

  const circle_tax_rate = ({
      circle_rate
    }) => {
      return (
        circle_rate == 0
          ? 0  // if circle_rate is set to 0, then 0 it is. Otherwise:
        //   : (circle_rate || '(not yet set)')
          : (circle_rate || '(' + t('circle.tax_rate.not_yet_set') + ')')
      );
    };

  const get_shortnames_of_circle =({
      cShoppers, circle_id  // cShoppers is circleShoppers
    }) => {
      //console.log('gsoc params:', cShoppers, circle_id);
      const shortnameOfIdentifier =
        cShoppers.filter(cs => cs.circle_id == circle_id)
                 .map(cs => [cs.shopper.identifier, cs.shopper.shortname]);

      return Object.fromEntries(shortnameOfIdentifier);  // converts map to obj
    } 

  const is_shopper_a_circle_leader =({
      circles, shopper_id
    }) => {
      console.log('isacl params:', circles, shopper_id);
      return circles.find(c => {
        return c.leader_id == shopper_id
      });
    } 

  const shopper_is_leader_of_these_circles = ({
      circles, shopper_id
    }) => {
      console.log('silotc params:', circles, shopper_id);
      return circles.filter(c => {
        return c.leader_id == shopper_id
      });
    } 

  const is_shopper_a_circle_admin =({
      cShoppers, circle_id, shopper_id  // cShoppers is circleShoppers
    }) => {
      console.log('isaca params:', cShoppers, circle_id, shopper_id);
      return cShoppers.find(cs => {
        return cs.circle_id == circle_id  &&  cs.shopper_id == shopper_id  &&  cs.shopper_is_admin
      });
    } 

  const is_shopper_admin_of_some_circle =({
      cShoppers, shopper_id  // cShoppers is circleShoppers
    }) => {
      return cShoppers.find(cs => {
        return cs.shopper_id == shopper_id  &&  cs.shopper_is_admin
      });
    } 

  const shoppers_order_for_this_sku_this_trip =({
      oItems, shopper_id, sku_id, trip_id
    }) => {
      return oItems.find(item =>
        item.trip.id == trip_id
        && item.sku.id == sku_id
        && item.shopper.id == shopper_id
      );
    }

  const getSpinString = ({place}) => {
    switch (place % 3) {
      case 0:
        return '*__';
      case 1:
        return '_*_';
      case 2:
        return '__*'
      default:
        return '_*_';
    }
  };
 
  // can find time in seconds for a date string (dObj) or else for 'now'
  const getTimeInSeconds = (dObj) => {
    const dateObj =  dObj === undefined  ?  new Date()  :  new Date(dObj);
    return Math.round(dateObj.getTime() / 1000);
  };

  const randomChar = ({chars}) => {
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  const stringGen = ({
    len = 5,
    charsetAlpha  = "abcdefghijklmnpqrstuvwxyz",
    charsetDigits = "123456789"
    // omit 'o' and '0' from 'charset' since they are hard to distinguish
  }) => {
    const charset = charsetAlpha + charsetDigits;

    const text = [ ];

    // We'll force input of a digit in the string to avoid spelling a word
    for( let i=0; i < len; i++ ) {
      switch (i) {
        case 1:
          text.push( randomChar({chars: charsetAlpha}) );
          break;
        case 2:
          text.push( randomChar({chars: charsetDigits}) );
          break;
        default:
          text.push( randomChar({chars: charset}) );
      }
    }
  
    return text.join('');
  }


  function s_by_identifier( a, b )
    {
      if ( a.identifier.toLowerCase() < b.identifier.toLowerCase()){
        return -1;
      }
      if ( a.identifier.toLowerCase() > b.identifier.toLowerCase()){
        return 1;
      }
      return 0;
    }

  function s_by_identifier_postal_code( a, b )
    {
      if ( a.identifier.toLowerCase() < b.identifier.toLowerCase()){
        return -1;
      }
      if ( a.identifier.toLowerCase() > b.identifier.toLowerCase()){
        return 1;
      }
      if ( a.postal_code < b.postal_code){
        return -1;
      }
      if ( a.postal_code > b.postal_code){
        return 1;
      }
      return 0;
    }

  const any_identifiers_in_multiple_postal_codes = ({circles}) => {
    // TODO: Find a better way to determine if any of the
    // shopper's circles share the same identifier. This can happen if
    // they are members of shopping circles in different postal codes
    // that have the same identifier.

    //console.log('setmyst circles', circles)
    // Create a unique set of identifier/postal_code strings ("cblobs")
    // Assumes all circles have the same value country, i.e. 'US'
    const cblobs = new Set(
      circles.map( c => "".concat(c.identifier, '/', c.postal_code) )
    );
    //console.log('setmyst cblobs', cblobs.keys())

    // Create a set of the various identifier terms of the cblobs
    const identifiers = new Set(
      Array.from(cblobs).map( blob => blob.split('/')[0] )
    )
    //console.log('setmyst identifiers', identifiers.keys())

    // If the count of identifiers is less than the count of blobs then
    // some of the identifiers must have been found in multiple postal_codes
    return cblobs.size > identifiers.size
  }

  function s_by_created_at( a, b )
    {
      if ( a.created_at < b.created_at ){
          return -1;
      }
      if ( a.created_at > b.created_at ){
        return 1;
      }
      return 0;
    }

  const toMmmDdYyyy = ({
      dateString,  // e.g. "2020-04-03"
      // The fact that the application's API may have already mapped this date
      // from the utc-based date in the database to the date appropriate for the
      // circle's time zone is invisible to the browser.
      // Since a time zone is not part of the received string, the string will
      // be treated as a utc-based date.
      weekday,
    }) => {
      if (dateString) {
        const dateObj = new Date(dateString);
        // console.log("toMmmDdYyyy in", dateObj)
        //console.log("toMmmDdYyyy in", dateObj.toString())
        
        const options = {
          weekday: weekday,
          month: "short",
          day: "numeric",
          year: "numeric",
          timeZone: "utc",
          // 'utc' is needed to fix output zone to same as input zone ('utc').
          // Else it thinks date to show is based on the local zone, e.g.
          // the time zone of the shopper's browser, which might be
          // "America/New_York", which is 4-5 hours behind utc, 8 PM yesterday,
          // which causes showing yesterday's date.
        };
      
        const newDateString = dateObj.toLocaleDateString(undefined, options);
        // const newDateString = dateObj.toLocaleDateString(t('global.locale'), options);
        //const newDateString = dateObj.toLocaleDateString('fr', options);  // looks French!
        //console.log("toMmmDdYyyy out", newDateString);
        return newDateString;
        // e.g. return dateObj.toLocaleDateString("en-US", options);
      } else {

        return '';
      }
    };

  const toHhColonMm = ({
      timeString,  // e.g. "1400", "0900", "900"
    }) => {
      const str = '0' + timeString.toString();

      return  str.slice(-4, -2) + ":" + str.slice(-2);
    };

  const toHmmA = ({
      timeString,  // e.g. "1400", "0900", "900"
    }) => {
      console.log('uutimeString', timeString);
      // Not sure why 'timeString' isn't respecting string methods, so do:
      const str = timeString.toString();

      return DateTime.fromFormat(str, 'Hmm')
                     .toFormat('h:mm a');
      // TODO: would be nice:
      //             .setLocale(t('global.locale'))
      //             // e.g. .setLocale('en')
      //             .toLocaleString('h:mm a');
      //             // e.g. 2:00 PM
    };

  return {
    toHhColonMm,
    toHmmA,
    toMmmDdYyyy,
    getSpinString,
    getTimeInSeconds,
    randomChar,
    stringGen,
    s_by_created_at,
    s_by_identifier,
    s_by_identifier_postal_code,
    any_identifiers_in_multiple_postal_codes,
    get_shortnames_of_circle,
    is_shopper_a_circle_admin,
    is_shopper_a_circle_leader,
    shopper_is_leader_of_these_circles,
    is_shopper_admin_of_some_circle,
    shoppers_order_for_this_sku_this_trip,
    circle_tax_rate
  }
}