import React, { } from 'react';
import HeadingBuilder from './HeadingBuilder';
import * as Constants from '../constants';

export const RadioButton = ({ label, tippyAlert, tippyInfo, isChecked, onChange }) => {
  return (
    <div>
      <label className='container-left'>
        <input
          type="radio"
          checked={isChecked}
          onChange={onChange}
        />
        &nbsp;
        <HeadingBuilder
          headingText    = {label}
          tippyAlertText = {tippyAlert}
          tippyText      = {Constants.DOT}
          tippyInfoText  = {tippyInfo}
        />
      </label>
    </div>
  );
};

export default RadioButton;