import React from 'react';
import { useTranslation } from "react-i18next";
import { HeadingBuilder } from "./HeadingBuilder";
import { SkuCategoryPicker } from "./SkuCategoryPicker";

const SkuMenuFilters = ({
  handleCategoryChange,
  handleBrandChange,
  handleNameChange,
  initializeFilters
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <HeadingBuilder
        containerType  = 'h6'
        Size           = 'strong'
        headingText    = {t('sku.category.filterBy')}
        // Filter by:
        tippyAlertText = {t('sku.category.filterByTippyContent')}
        tippyText      = {t('sku.category.filterByTippyContent')}
      />
      {/* <br/> */}
      {t('sku.category.label')}&nbsp;
      {/* Category */}
      <SkuCategoryPicker
        catName      = "filter"
        catId        = "category_skmf"
        catLabel     = {t('sku.category.showAll')}
        handleChange = {handleCategoryChange}
      />
      <br/>
      
      {t('global.label.brand')}&nbsp;
      {/* Brand */}
        <input onChange={handleBrandChange} placeholder={t('sku.category.enterBrand')}/>
        {/* Enter Brand */}
      <br/>

      {t('sku.label.label')}&nbsp;
      {/* Name/Label */}
        <input onChange    = {handleNameChange}
               placeholder = {t('sku.category.enterCharacters')}/>
        {/* Enter any characters */}
      <br/>

      <button onClick={initializeFilters}>{t('sku.category.buttonText')}</button>
      {/* Hide &amp; Reset Filters */}
    </div>
  )
}

export default SkuMenuFilters;