export const useStyles = () => {

  const tableShow = {
    maxWidth: "700px",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const tableShowNarrow = {
    maxWidth: "250px",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const tableData = {
    textAlign: "left",
    //margin: 20,
    //padding: "10px",
    //padding: 10,
    // From https://www.w3schools.com/react/react_css.asp
    // color: "white",
    // backgroundColor: "DodgerBlue",
    // padding: "10px"
    // fontFamily: "Arial"
    border: 1
  };

  const width100 = {
    width: "100%",
  };

  const tippyUl = {
    listStyleType: "circle",
    listStylePosition: "outside",
    textAlign: "left",
  };

  const textAlignLeft = {
    textAlign: "left",
  };

  const tableAlignRight = {
    textAlign: "right",
  };

  const tableOrderItem = {
    //border: 1,
    //textAlign: "left"
    borderTop: '2px solid #00944a',
    borderBottom: '2px solid #00944a'
    // A shade of 'girl-scout-green' per https://colorcodes.io/green/girl-scout-green-color-codes/
  };

  const altBorderTopColor = {
    //border: 1,
    //textAlign: "left"
    borderTop: '2px solid #00944a',
    // A shade of 'girl-scout-green' per https://colorcodes.io/green/girl-scout-green-color-codes/
  };

  // "#ffff99" is a light yellow shade
  const editBoxStyle = ({cellQty,
      changeColorOfBox = false,
      normalBoxColor = "",
      editBoxColor = ""
    }) => {
    return ({
      width: "60px",
      backgroundColor: changeColorOfBox ? editBoxColor : normalBoxColor,
      fontWeight: cellQty > 0 ? 'bold' : ''
    })
  }

  return {
    tableShow,
    tableShowNarrow,
    tableData,
    tableAlignRight,
    tableOrderItem,
    textAlignLeft,
    altBorderTopColor,
    width100,
    tippyUl,
    editBoxStyle 
  }
}