import React from 'react';

import { BiSearchAlt2 } from "react-icons/bi";
import { BiHide } from "react-icons/bi";
import { IoIosRefresh } from "react-icons/io";
import { IoMdHelpCircleOutline } from "react-icons/io";
import { IoBuildOutline } from "react-icons/io5";
import { TiArrowForwardOutline } from "react-icons/ti";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { AiOutlineSound } from "react-icons/ai";
import { BsCameraReels } from "react-icons/bs";
import { MdChangeHistory } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { RiAsterisk } from "react-icons/ri";

const renderSwitch = (param) => {
  switch(param) {
    case 'arrowRight':
      return < TiArrowForwardOutline />;
    case 'change':
      return < MdChangeHistory />;
    case 'circleInfo':
      return < AiOutlineInfoCircle />;
    case 'circleHook':
      return < IoMdHelpCircleOutline />;
    case 'hide':
      return < BiHide />;
    case 'refresh':
      return < IoIosRefresh />;
    case 'plus':
      return < FiPlus />;
    case 'wrench':
      return < IoBuildOutline />;
    case 'magnify':
      return < BiSearchAlt2 />;
    case 'asterisk':
      return < RiAsterisk />;
    case 'audioPlayback':
      return < AiOutlineSound />;
    case 'videoPlayback':
      return < BsCameraReels />;
    // case 'videoPlayback1.5em':
    //   return < BsCameraReels size={'1.5em'} />;
    default:
      return param;
  }
}

export const GetIcon = ( {toGet} ) => {
  return renderSwitch(toGet);
}

export default GetIcon