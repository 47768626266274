import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import { brandStr } from '../utils';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Table from 'react-bootstrap/Table';
import { HeadingBuilder } from './HeadingBuilder';
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";
import styles from '../css/trTdFirstChild.module.css';

export const PriceForm = () => {
    const { signedIn, skus, addPrice } = useContext(ShopperContext);
    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { setJustGotClearToFalse } = useContext(ShopperContext);

    const { tableShow, tableData, altBorderTopColor } = useStyles();
    const { t } = useTranslation();
    const {sku_id} = useParams();
    console.log('pf sku_id: ', sku_id)
    const sku = skus.find(s => s.id == sku_id);

    if (sku == null) {
      console.log('pf sku2: ', sku)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'sku' to be 'undefined'.
      // Let's go back to the 'skus' page.
      window.location.replace('/skus/');
    }

    const isWeighty  =  sku.type == "WeightyItem" ? true : false;

    const initialState = {
      sku_id: sku.id,
      currency: '$',
      price_date: '',
      discount_amount: '0',
      discount_start_date: '',
      discount_end_date: '',
      price: '',
      // unit_price: '',
    };
    console.log("pf: initialState", initialState);

    const priceLabelTippy =
      isWeighty
        ? t('sku.price.weightyTippyContent')
        : t('sku.price.tippyContent') + t('sku.price.currencyCautionTippyContent')

    const { formData, handleChange } = useForm(initialState);

    const skuBrand = sku.brand || '__';

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();
        
            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();
        
            console.log('formData for addPrice: ', formData);
            addPrice(formData);
        
            // If there is an error we want to preserve formData and show the message.
        }

        return (
          <article>
            <HeadingBuilder
              Size        = 'h3'
              headingText = {t('price.formPart.addPriceLabel')}
              value       = {brandStr({brand: skuBrand}) + sku.label}
                          // Add Price for: FP - Chicken Drumsticks
            />
            {t('price.formPart.subHeadlineLabel')} {sku.identifier}<br/>
            <br/>
            <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText =
            { isWeighty ? t('sku.price.weightyLabel') : t('sku.price.label') }
            // Price (decimal)
          labelHtmlFor = "price"
          tippyAlertText = {priceLabelTippy}
          tippyText      = {priceLabelTippy}
          // A number showing the price of the item. Don't include a currency symbol such as '$'.
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="110"
          type="text"
          id="price"
          name="price"
          onChange={handleChange}
          value={formData.price}
          decimalScale={2}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.price_date.label')}
          // Price 'AsOf' Date
          labelHtmlFor   = "price_date"
          tippyAlertText = {t('sku.price_date.tippyContent')}
          tippyText      = {t('sku.price_date.tippyContent')}
          // When did/will the value in Price become the price? ShopWeAll will use the most recent 'Price AsOf Date' before a trip to price an item.
        />
      </td>
      <td>
        <input
          key="115"
          type="date"
          id="price_date"
          name="price_date"
          onChange={handleChange}
          value={formData.price_date}
        />
        &nbsp;*
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_amount.label')+' $'}
          // Discount Amount
          labelHtmlFor   = "discount_amount"
          tippyAlertText = {t('sku.discount_amount.tippyContent')}
          tippyText      = {t('sku.discount_amount.tippyContent')}
          // Value of any discount. This will be subtracted from the item's price to show the purchase price.
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="120"
          type="text"
          id="discount_amount"
          name="discount_amount"
          onChange={handleChange}
          value={formData.discount_amount}
          decimalScale={2}
        />
      </td>
    </tr>
  {
  false &&
      // Hardcoding with 'false' hides row for now. Maybe Circle should set this; here could be override, like how trip's tax rates can override circle's tax settings.
    <tr>
      <td>
        {/* Currency Symbol */}
        <label htmlFor="currency">{t('sku.currency.label')}</label>
      </td>
      <td>
        <input
          key="100"
          type="text"
          id="currency"
          name="currency"
          onChange={handleChange}
          value={formData.currency}
          hidden={true}
        />
      </td>
    </tr>
  }
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_start_date.label')}
          // Discount Start Date
          labelHtmlFor   = "discount_start_date"
          tippyAlertText = {t('sku.discount_start_date.tippyContent')}
          tippyText      = {t('sku.discount_start_date.tippyContent')}
          // When will the discount begin? We start by assuming any discount is effective immediately, but you can change this.
        />
      </td>
      <td>
        <input
          key="140"
          type="date"
          id="discount_start_date"
          name="discount_start_date"
          onChange={handleChange}
          value={formData.discount_start_date}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_end_date.label')}
          // Discount End Date
          labelHtmlFor   = "discount_end_date"
          tippyAlertText = {t('sku.discount_end_date.tippyContent')}
          tippyText      = {t('sku.discount_end_date.tippyContent')}
          // When will the discount end?
        />
      </td>
      <td>
        <input
          key="150"
          type="date"
          id="discount_end_date"
          name="discount_end_date"
          onChange={handleChange}
          value={formData.discount_end_date}
        />
      </td>
    </tr>
  </tbody>
</Table>
        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
        <input key="170" type='submit' value='Submit' />

            </form>
            { errorsDoExist && <ErrorList /> }
            <BackButton />
          </article>
        )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default PriceForm;