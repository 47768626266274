import React from 'react';
import { useTranslation } from "react-i18next";

export const SkuCategoryPicker = ({
    catKey,
    catId = 'skuCategoryPicker',
    catName = "category",
    catLabel = "",
    handleChange,
    formData,
  }) => {

  const { t } = useTranslation();

  // set a default value for 'catLabel' since 't' not available until now
  const catLabelToShow =
    catLabel.length > 0  ? catLabel : t('sku.category.optionValue')
    // Choose one:

  return (
    <select
      key          = {catKey}
      type         = "text"
      id           = {catId}  // id="category_skef"
      name         = {catName}
      onChange     = {handleChange}
      value        = {formData == undefined  ? undefined : formData.category}
    >
      <option value="">{catLabelToShow}</option>
      <option value="0" >{t('sku.category.optionValue00')}</option>  {/* Miscellaneous */}
      <option value="1" >{t('sku.category.optionValue01')}</option>  {/* Fruits */}
      <option value="2" >{t('sku.category.optionValue02')}</option>  {/* Vegetables */}
      <option value="3" >{t('sku.category.optionValue03')}</option>  {/* Bread */}
      <option value="4" >{t('sku.category.optionValue04')}</option>  {/* Baking */}
      <option value="5" >{t('sku.category.optionValue05')}</option>  {/* Bakery */}
      <option value="6" >{t('sku.category.optionValue06')}</option>  {/* Dessert */}
      <option value="7" >{t('sku.category.optionValue07')}</option>  {/* Dairy */}
      <option value="8" >{t('sku.category.optionValue08')}</option>  {/* Meat */}
      <option value="9" >{t('sku.category.optionValue09')}</option>  {/* Seafood */}
      <option value="10">{t('sku.category.optionValue10')}</option>  {/* Alternative Protein */}
      <option value="11">{t('sku.category.optionValue11')}</option>  {/* Nuts */}
      <option value="12">{t('sku.category.optionValue12')}</option>  {/* Deli */}
      <option value="13">{t('sku.category.optionValue13')}</option>  {/* Soups */}
      <option value="14">{t('sku.category.optionValue14')}</option>  {/* Cans, Jars */}
      <option value="15">{t('sku.category.optionValue15')}</option>  {/* Pasta, Rice, Cereal */}
      <option value="16">{t('sku.category.optionValue16')}</option>  {/* Sauces */}
      <option value="17">{t('sku.category.optionValue17')}</option>  {/* Condiments */}
      <option value="18">{t('sku.category.optionValue18')}</option>  {/* Herbs, Spices */}
      <option value="19">{t('sku.category.optionValue19')}</option>  {/* Refrigerated Items */}
      <option value="20">{t('sku.category.optionValue20')}</option>  {/* Frozen Foods */}
      <option value="21">{t('sku.category.optionValue21')}</option>  {/* Snacks */}
      <option value="22">{t('sku.category.optionValue22')}</option>  {/* Beverages */}
      <option value="23">{t('sku.category.optionValue23')}</option>  {/* Paper Goods */}
      <option value="35">{t('sku.category.optionValue35')}</option>  {/* Kitchen */}
      <option value="24">{t('sku.category.optionValue24')}</option>  {/* Household, Cleaning */}
      <option value="25">{t('sku.category.optionValue25')}</option>  {/* Personal Care, Beauty */}
      <option value="26">{t('sku.category.optionValue26')}</option>  {/* Health, Vitamins */}
      <option value="27">{t('sku.category.optionValue27')}</option>  {/* Pharmacy */}
      <option value="28">{t('sku.category.optionValue28')}</option>  {/* Baby Products */}
      <option value="29">{t('sku.category.optionValue29')}</option>  {/* Pet Care */}
      <option value="30">{t('sku.category.optionValue30')}</option>  {/* Office Supplies */}
      <option value="31">{t('sku.category.optionValue31')}</option>  {/* Clothing */}
      <option value="32">{t('sku.category.optionValue32')}</option>  {/* Automotive */}
      <option value="33">{t('sku.category.optionValue33')}</option>  {/* Electronics */}
      <option value="34">{t('sku.category.optionValue34')}</option>  {/* Patio, Lawn, Garden */}
    </select>
  )}

export default SkuCategoryPicker