import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants'
import { usePhrasing } from "../hooks/useSkuUtils";
import { HeadingBuilder } from "./HeadingBuilder";
import { useTranslation } from "react-i18next";

export const SkuLink = ({
    sku,
    if_shopper_is_circle_admin,
    showCircleZipCodes,
    showIdentifier,
    showStore,
    showRemoveSkuColumn
  }) => {
  const { circles, deleteSku } = useContext(ShopperContext);
  console.log('skulink circles', circles)

  const { weightAndUnitsOfSku } = usePhrasing();
  const { t } = useTranslation();

  if (sku) {
  console.log('skulink skuX', sku)
  } else {
    return 
  }

  console.log('skulink sku.circle', sku.circle)
  const circle = circles.find(c => c.id == sku.circle.id);
  console.log('skulink circle A', circle)

  async function removeSkuFromCircle (e) {
    // TODO: is event (e) useful for anything?

    // Delete a sku of the circle.
    // Backend only allows circle's admins to delete skus.

    // Check that 'sku' object seems real:
    console.log("rsfc sku", {sku})
    const skuIsFalse = Object.is(sku,null) ||
                                sku === undefined ||
                                !sku.hasOwnProperty('id');

    console.log("rsfc before ds", Date.now() );
    const rc = await deleteSku(skuIsFalse ? null : sku.id);
    console.log("rsfc rc", rc);
    console.log("rsfc after ds", Date.now() );

    // Rails should have deleted orderItems and prices for skus of the circle.

    // It looks like 'navigate' trick is unnecessary after removing a sku
    // since we weren't on a sku-based url when doing so. Hence, 'Back'
    // doesn't blow up. Deleting via a 'Remove' button creates distance from
    // the delete.
  }

  return (
    <tr>
      <td>
        <Link to={`/circles/${circle.id}`}>
          {circle.identifier}
        </Link>
      </td>

      { showCircleZipCodes
        ? <td>
            {circle.postal_code}
          </td>
        : null
      }

      <td>
        { showIdentifier
          ? sku.identifier.length > Constants.MAX_SKU_IDENTIFIER_CHARS_BEFORE_TIPPY
            ? < HeadingBuilder
                containerType  = 'ellipsis fw-bold'
                headingText    = {sku.identifier}
                tippyAlertText = {sku.identifier}
                tippyText      = {sku.identifier}
                tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
              />
            : <b>{sku.identifier}</b>
              
          : < HeadingBuilder
              headingText    = {Constants.DOT}
              tippyAlertText = {sku.identifier}
              tippyText      = {sku.identifier}
              tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
              forceShowAlertText = '1'
            />
        }
      </td>

      <td>
        { showStore
          ? sku.store_label.length > Constants.MAX_SKU_STORE_CHARS_BEFORE_TIPPY
            ? < HeadingBuilder
                containerType  = 'ellipsis'
                headingText    = {sku.store_label}
                tippyAlertText = {sku.store_label}
                tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
                tippyText      = {sku.store_label}
              />
            : <>{sku.store_label}</>
              
          : < HeadingBuilder
              headingText    = {Constants.DOT}
              tippyAlertText = {sku.store_label}
              tippyAlertIcon = {Constants.GET_ICON_ASTERISK}
              tippyText      = {sku.store_label}
              forceShowAlertText = '1'
            />
        }
      </td>

      <td>
        <Link to={`/skus/${sku.id}`}>
          {sku.label}
        </Link>
        {
          weightAndUnitsOfSku({
            sku,
            showWeightAsSkuEditLink: false,
            showUnitCount: true
          })
        }
      </td>

      <td>
        {sku.is_hidden ? 'Hidden' : ''}
      </td>

      <td>
        {/* Show 'Remove' button if shopper is admin of the circle. */}
        { (showRemoveSkuColumn && if_shopper_is_circle_admin)
          ? <HeadingBuilder
              buttonAction   = {removeSkuFromCircle}
              headingText    = {t('global.label.remove')}
              // Remove
              tippyAlertText = {t('sku.removeSku.tippyContent')}
              tippyText      = {t('sku.removeSku.tippyContent')}
            />
          : null
        }
      </td>

    </tr>
  )
}

export default SkuLink