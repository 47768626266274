import React, { useContext } from 'react';
import { ShopperContext } from '../context/shopper'
import { useStyles } from "../hooks/useStyles";
import { useUtils } from "../hooks/useUtils";
import Checkbox from '../components/Checkbox';
import TripCostLink from '../components/TripCostLink'
import HeadingBuilder from '../components/HeadingBuilder'
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";

export const TripCost = ({
    trip,
    showTripCost,
    handleTcClick,
    tripCosts,
    tripCostsWithTax
  }) => {

  const { signedIn, circleShoppers } = useContext(ShopperContext);
  console.log('tc tripCostsA: ', (typeof tripCosts));
  console.log('tc tripCostsB: ', tripCosts);
  console.log('tc tripCostsC: ', tripCostsWithTax);

  const { tableShow, textAlignLeft } = useStyles(); 
  const { get_shortnames_of_circle, toMmmDdYyyy, toHmmA } = useUtils();
  const { t } = useTranslation();

  if (signedIn) {
      function s_by_shopper_identifier( a, b )
      {
        if ( a< b){
            return -1;
        }
        if ( a> b){
          return 1;
        }
        return 0;
      }

      const shopperShortname = get_shortnames_of_circle({
        cShoppers: circleShoppers,
        circle_id: trip.circle.id
      });

      const shoppersList = Object.keys(tripCosts)
        .sort( s_by_shopper_identifier )
        .map(shopperName => <TripCostLink
            key = {shopperName}
            shortname = {shopperShortname[shopperName] || shopperName}
            outlay = {tripCosts[shopperName]}
            outlayWithTax = {tripCostsWithTax[shopperName]}
            currency = {'$'}
          />
        );
      console.log('TripCost.js shoppersList: ', shoppersList);

      return (
          <article>
              <hr/>
              <HeadingBuilder
                Size           = 'h4'
                headingText    = {t('trip.formPart.tripCostHeadlineLabel')}
                // Planned Outlays by Shopper
                tippyAlertText = {t('trip.tripCost.TippyContent')}
                tippyText      = {t('trip.tripCost.TippyContent')}
                tippyInfoText  = {t('trip.tripCost.TippyInfoContent')}
              />

              {t('trip.formPart.shoppingTripPrompt')}
              {/* Shopping Trip: */}
                { toMmmDdYyyy({dateString: trip.day, weekday: 'short'}) },
                &nbsp;{ toHmmA({timeString: trip.hour}) },
                &nbsp;{ trip.label }
              <br />
              <HeadingBuilder
                headingText = {t('global.label.circle')}
                value       = {trip.circle.identifier}
                // Circle oipuy
              />
              <Table striped bordered hover size="sm" style={tableShow}>
              <thead style={textAlignLeft}>
                <tr>
                  <th>{t('shopper.shortname.label')}</th>
                  {/* Shortname */}
                  <th>{t('global.label.outlay')}</th>
                  {/* Outlay */}
                  <th>{t('trip.formPart.withTax')}</th>
                  {/* with Tax */}
                </tr>
              </thead>
              <tbody style={textAlignLeft}>
                {shoppersList}
              </tbody>
              </Table>
              <Checkbox
                isChecked    = {showTripCost}
                setIsChecked = {handleTcClick}
                label        = {t('ordersList.button_text.showTripCost')}
                // Show Trip Cost
                tippyAlert   = {t('trip.tripCost.ckBoxToHideTippyContent')}
              />
              <hr/>
          </article>
      )
  } else {
    return (
      <HeadingBuilder
        Size = 'h3'
        headingText = {t('global.notSignedInMessage')}
        // Not Authorized - Please Sign in or Sign up
      />
    )
  }
}

export default TripCost;
