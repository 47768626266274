import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import * as Constants from '../constants';
import { ErrorList } from './ErrorList';
import MoreInfoReTrip from './MoreInfoReTrip';
import { useForm } from "../hooks/useForm";
import { useUtils } from "../hooks/useUtils";
import { useStyles } from "../hooks/useStyles";
import { usePhrasing } from "../hooks/useSkuUtils";
import { useParams } from 'react-router-dom';
import { BackButton } from './BackButton';
import { HeadingBuilder } from './HeadingBuilder';
import { NumericFormat } from 'react-number-format';
import { useTranslation } from "react-i18next";
import Table from 'react-bootstrap/Table';
import styles from '../css/trTdFirstChild.module.css';

export const TripEditForm = () => {
    const { signedIn, shopper, trips, editTrip, circleShoppers,
            errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse,
            setJustGotClearToFalse,
          } = useContext(ShopperContext);

    console.log('tef trips: ', trips)

    const [showMoreTripInfo, setShowMoreTripInfo] = useState(false);

    const { tableShow, tableData, altBorderTopColor } = useStyles();

    const { tripTaxTippyText, tripFoodTaxTippyText } = usePhrasing();
    const tripTaxOverrideText     = tripTaxTippyText();
    const tripFoodTaxOverrideText = tripFoodTaxTippyText();

    const {id} = useParams();
    console.log('tef id: ', id)

    const { t } = useTranslation();
    const { circle_tax_rate, toHhColonMm } = useUtils();

    const tripObj = trips.find(c => c.id == id);
    console.log('tef tripObj: ', tripObj)

    if (tripObj == null) {
      console.log('tef tripObj2: ', tripObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'tripObj' to be 'undefined'.
      // Let's go back to the 'trips' page.
      window.location.replace('/trips/');
    }

    //const initialState = {...tripObj};
    const initialState = {
      id:        tripObj.id,
      label:     tripObj.label,
      day:       tripObj.day,
      hour:      toHhColonMm({timeString: tripObj.hour}),
      is_hidden: tripObj.is_hidden ? 1 : 0,
      tax_rate:  tripObj.tax_rate || '',
      circle_id: tripObj.circle.id,
      tax_rate_food:
                 tripObj.tax_rate_food || '',
      // duration: '',
      // distance: '',
      // est_bill: '',
      // actual_bill: '',
    };
    console.log("tef: initialState", initialState);

    const { formData, handleChange, reset, editFormValues } = useForm(initialState);

    const { is_shopper_a_circle_admin } = useUtils();

    const if_shopper_is_circle_admin = is_shopper_a_circle_admin({
      cShoppers: circleShoppers, circle_id: tripObj.circle.id, shopper_id: shopper.id
    });

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();
        
            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();
        
            console.log('formData for editTrip: ', formData);
            editTrip(formData);
        
            // If there is an error we want to preserve formData and show the message.
        }

        const handleMoreTripInfoClick = event => {
          setShowMoreTripInfo(current => !current);
        };

        return (
          <article>
            <h3>
              {/* Update planned Trip: */}
              {t('trip.formPart.updateTripHeadlineLabel')}
            </h3>
            <HeadingBuilder
              Size        = 'h6'
              headingText = {'(' + t('global.label.circle') + ':'}
              value       = {tripObj.circle.identifier + ')'}
              // Circle: am_circle
            />
            <br/>
            <b>{t('trip.formPart.reminder')}</b>{t('trip.formPart.reminderWarning')}
            {/* <b>Reminder:</b> Changing a trip date may lose history -&nbsp; */}
              { showMoreTripInfo
                ? <MoreInfoReTrip
                    circle_id = { tripObj.circle.id }
                    handleMoreTripInfoClick = { handleMoreTripInfoClick }
                  />
                : <button onClick={handleMoreTripInfoClick}>{t('global.moreInfo.text')}</button>
                  // More Info
              }
            <hr/>
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.label.label')}
          // Shopping Trip Description:
          labelHtmlFor   = "label_tef"
          tippyAlertText = {t('trip.label.tippyContent')}
          tippyText      = {t('trip.label.tippyContent')}
        />
      </td>
      <td>
        <input
          key="40"
          type="text"
          id="label_tef"
          name="label"
          maxLength={Constants.TRIP_LABEL_MAX_LENGTH}
          onChange={handleChange}
          value={formData.label}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.day.label')}
          // Date of Shopping Trip:
          labelHtmlFor   = "day_tef"
          tippyAlertText = {t('trip.day.tippyContent')}
          tippyText      = {t('trip.day.tippyContent')}
        />
      </td>
      <td>
        <input
          key="60"
          type="date"
          id="day_tef"
          name="day"
          // autoComplete="username"
          onChange={handleChange}
          value={formData.day}
        />
        &nbsp;*
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.hour.label')}
          // Time of Trip
          labelHtmlFor   = "hour_tef"
          tippyAlertText = {t('trip.hour.tippyContent')}
          tippyText      = {t('trip.hour.tippyContent')}
        />
      </td>
      <td>
        <input
          key="80"
          type="time"
          // presents a "time edit" box: 03:12 PM *
          id="hour_tef"
          name="hour"
          onChange={handleChange}
          value={formData.hour}
        />
        &nbsp;*
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        {/* Sales Tax Rate for Circle: 7% */}
        {t('trip.tax_rate.label_setup')}
      </td>
      <td>
        {circle_tax_rate({circle_rate: tripObj.circle.tax_rate})}
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.tax_rate.label')}
          // Trip Override Rate
          labelHtmlFor   = "tax_rate_tef"
          tippyAlertText = {tripTaxOverrideText}
          tippyText      = {tripTaxOverrideText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="90"
          type="text"
          id="tax_rate_tef"
          name="tax_rate"
          onChange={handleChange}
          value={formData.tax_rate}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        {/* Food Sales Tax Rate for Circle: 2.5% */}
        {t('trip.tax_rate_food.label_setup')}
      </td>
      <td>
        {circle_tax_rate({circle_rate: tripObj.circle.tax_rate_food})}
        &nbsp;%
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.tax_rate_food.label')}
          // Trip Override Rate
          labelHtmlFor   = "tax_rate_food_tef"
          tippyAlertText = {tripFoodTaxOverrideText}
          tippyText      = {tripFoodTaxOverrideText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="100"
          type="text"
          id="tax_rate_food_tef"
          name="tax_rate_food"
          onChange={handleChange}
          value={formData.tax_rate_food}
          decimalScale={3}
        />
        &nbsp;%
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        <HeadingBuilder
          containerType  = 'container-input-label'
          headingText    = {t('trip.is_hidden.label')}
          // Hidden
          labelHtmlFor   = "is_hidden_tef"
          tippyAlertText = {t('trip.is_hidden.tippyContent')}
          tippyText      = {t('trip.is_hidden.tippyContent')}
          // Do you want to reduce visibility of this Trip? e.g. not show it as one of the Circle's trips? etc.
        />
      </td>
          {/* TODO: We need to show current value of 'is_hidden' to be confident about what is changing. Review the method we are using here, i.e. "if is_hidden == 1" */}
      <td>
        <select
          key="150"
          type="text"
          id="is_hidden_tef"
          name="is_hidden"
          onChange={handleChange}
          value={formData.is_hidden}
        >
          {/* <option value="0">No</option> */}
          <option value="0">{t('trip.is_hidden.optionValue0')}</option>
          {/* <option value="1">Yes</option> */}
          <option value="1">{t('trip.is_hidden.optionValue1')}</option>
        </select>
      </td>
    </tr>
  </tbody>
</Table>
        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
          <input key="170" type='submit' value='Submit' />
        </form>
        { errorsDoExist && <ErrorList /> }
        <BackButton />
      </article>
     )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default TripEditForm;