import React, { useState, useContext } from 'react';
import { ShopperContext } from '../context/shopper';
import { ErrorList } from './ErrorList';
import { useForm } from "../hooks/useForm";
import { useStyles } from "../hooks/useStyles";
import MoreInfoRePrice from './MoreInfoRePrice';
import HeadingBuilder from './HeadingBuilder';
import { brandStr } from '../utils';
import { useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Table from 'react-bootstrap/Table';
import { BackButton } from './BackButton';
import { useTranslation } from "react-i18next";
//import styled from 'styled-components';
import styles from '../css/trTdFirstChild.module.css';

// consumers arrive here via: <Link to={`/skus/${sku.id}/prices/${price.id}`}>
export const PriceEditForm = () => {
    const { signedIn, skus, prices, editPrice } = useContext(ShopperContext);
    const { errorsDoExist, dismissErrorsList, setErrorsDoExistToFalse } = useContext(ShopperContext);
    const { setJustGotClearToFalse } = useContext(ShopperContext);
    console.log('pef prices: ', prices)

    const [showMoreInfo, setShowMoreInfo] = useState(false);
    const { tableShow, tableData, altBorderTopColor } = useStyles();

    const {sku_id, id} = useParams();
    console.log('pef sku_id: ', sku_id)
    console.log('pef id: ', id)

    const priceObj = prices.find(p => p.id == id);
    const sku = skus.find(s => s.id == sku_id);
    console.log('pef priceObj: ', priceObj)

    if (priceObj == null) {
      console.log('pef priceObj2: ', priceObj)
      // User likely used browser to refresh the page, lost access to state.
      // This causes 'priceObj' to be 'undefined'.
      // Let's go back to the 'skus' page.
      window.location.replace('/skus/');
    }

    // Hmm, if we edit priceObj.discount_end_date when it was originally null,
    // the system complains. If it was null, the discount was likely 0, so not
    // awful if set discount_end_date to price_date
    // (FIX ME could check if discount is 0 - and then what???)
    if (priceObj.discount_end_date === undefined || priceObj.discount_end_date === null) {
        priceObj.discount_end_date = priceObj.price_date;
    }
    // Also seems reasonable to set the (new) discount_start_date to price_date
    // Hmm, I'm thinking this first test for 'undefined' is covered by the 2nd's
    // 'null' test, so remove the 'undefined' test?
    if (priceObj.discount_start_date === undefined || priceObj.discount_start_date === null) {
        priceObj.discount_start_date = priceObj.price_date;
    }

    //console.log('PriceEditForm.js skuType: ', sku.type)
    //const isDiscrete =  sku.type == "DiscreteItem" ? true : false;
    //const isGranular =  sku.type == "GranularItem" ? true : false;
    const isWeighty  =  sku.type == "WeightyItem" ? true : false;

    //sku.type ||= isGranular ? 'GranularItem' : (isWeighty ? 'WeightyItem' : 'DiscreteItem');

    const { t } = useTranslation();
    const initialState = {...priceObj};
    // const initialState = {
      // currency: "$"
      // discount_amount: "0.0"
      // discount_end_date: "2022-09-11"
      // discount_start_date: "2022-09-11"
      // id: 5
      // price: "11.99"
      // price_date: "2022-09-11"
      // sku: {id: 26, circle_id: 17, identifier: '6843738975', label: 'Dark Chocolate Acai', detail: 'Balls of smooth dark chocolate with a fruit flavored center, yum!', …}
      // unit_price: null
    // };
    console.log("pef: initialState", initialState);
    //console.log("pef: sku", sku);

    const { formData, handleChange, reset, editFormValues } = useForm(initialState);

    const priceTippyText =
      isWeighty
        ? t('sku.price.weightyTippyContent')
        : t('sku.price.tippyContent') + t('sku.price.currencyCautionTippyContent')

    const skuBrand = sku.brand || '__';

    if (signedIn) {
        const handleSubmit = (e) => {
            e.preventDefault();
        
            // clear out any error messages, success statuses
            dismissErrorsList();
            setErrorsDoExistToFalse();
            setJustGotClearToFalse();
        
            console.log('formData for editPrice: ', formData);
            editPrice(formData);
        
            // If there is an error we want to preserve formData and show the message.
        }

        const handleMoreInfoClick = event => {
          setShowMoreInfo(current => !current);
        };

        return (
      <article>
        <HeadingBuilder
          Size        = 'h3'
          headingText = {t('price.formPart.editPriceLabel')}
          value       = {brandStr({brand: skuBrand}) + sku.label}
                      // Edit Price for: FP - Chicken Drumsticks
        />
        {t('price.formPart.subHeadlineLabel')} {sku.identifier}<br/>
        {/* Sku {sku.identifier} */}
        <b>{t('price.formPart.reminder')}</b>{t('price.formPart.reminderWarning')}
        {/* Reminder: Changing a price may have consequences -&nbsp; */}
        { showMoreInfo
          ? <MoreInfoRePrice
              sku = {sku}
              handleMoreInfoClick = { handleMoreInfoClick }
            />
          : <button onClick={handleMoreInfoClick}>{t('global.moreInfo.text')}</button>
            // More Info
        }
        <hr/>
        <form onSubmit={handleSubmit}>
<Table responsive striped bordered hover size="sm" style={tableShow}>
  <thead >
  </thead>
  <tbody className={styles.trTdFC} style={tableData} >
    <tr>
      <td>
        <HeadingBuilder
          containerType='container-input-label'
          headingText =
            { isWeighty ? t('sku.price.weightyLabel') : t('sku.price.label') }
            // Price (decimal)
          labelHtmlFor = "price_pef"
          tippyAlertText = {priceTippyText}
          tippyText      = {priceTippyText}
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="110"
          type="text"
          id="price_pef"
          name="price"
          onChange={handleChange}
          value={formData.price}
          decimalScale={2}
        />
        &nbsp;*<br/>
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.price_date.label')}
          // Price 'AsOf' Date
          labelHtmlFor   = "price_date_pef"
          tippyAlertText = {t('sku.price_date.tippyContent')}
          tippyText      = {t('sku.price_date.tippyContent')}
          // When did/will the value in Price become the price? ShopWeAll will use the most recent 'Price AsOf Date' before a trip to price an item.
        />
      </td>
      <td>
        <input
          key="115"
          type="date"
          id="price_date_pef"
          name="price_date"
          onChange={handleChange}
          value={formData.price_date}
        />
        &nbsp;*
      </td>
    </tr>
    <tr style={altBorderTopColor}>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_amount.label')+' $'}
          // Discount Amount
          labelHtmlFor   = "discount_amount_pef"
          tippyAlertText = {t('sku.discount_amount.tippyContent')}
          tippyText      = {t('sku.discount_amount.tippyContent')}
          // Value of any discount. This will be subtracted from the item's price to show the purchase price.
        />
      </td>
      <td>
        <NumericFormat displayType="input"
          key="120"
          type="text"
          id="discount_amount_pef"
          name="discount_amount"
          onChange={handleChange}
          value={formData.discount_amount}
          decimalScale={2}
        />
      </td>
    </tr>
  {
      // Hardcoding with 'false' hides row for now. Maybe Circle should set this; here could be override, like how trip's tax rates can override circle's tax settings.
  false &&
    <tr>
      {/* Currency Symbol */}
      <td>
        <label htmlFor="currency_pef">{t('sku.currency.label')}</label>
      </td>
      <td>
        <input
          key="100"
          type="text"
          id="currency_pef"
          name="currency"
          onChange={handleChange}
          value={formData.currency}
          hidden={true}
        />
      </td>
    </tr>
  }
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_start_date.label')}
          // Discount Start Date
          labelHtmlFor   = "discount_start_date_pef"
          tippyAlertText = {t('sku.discount_start_date.tippyContent')}
          tippyText      = {t('sku.discount_start_date.tippyContent')}
          // When will the discount begin? We start by assuming any discount is effective immediately, but you can change this.
        />
      </td>
      <td>
        <input
          key="140"
          type="date"
          id="discount_start_date_pef"
          name="discount_start_date"
          onChange={handleChange}
          value={formData.discount_start_date}
        />
      </td>
    </tr>
    <tr>
      <td>
        <HeadingBuilder
          containerType  = "container-input-label"
          headingText    = {t('sku.discount_end_date.label')}
          // Discount End Date
          labelHtmlFor   = "discount_end_date_pef"
          tippyAlertText = {t('sku.discount_end_date.tippyContent')}
          tippyText      = {t('sku.discount_end_date.tippyContent')}
          // When will the discount end?
        />
      </td>
      <td>
        <input
          key="150"
          type="date"
          id="discount_end_date_pef"
          name="discount_end_date"
          onChange={handleChange}
          value={formData.discount_end_date}
        />
      </td>
    </tr>
  </tbody>
</Table>
        {/* TODO: ?Will translating Submit be handled automatically by html?
            https://www.w3.org/International/wiki/Locale-based_forms */}
          <input key="170" type='submit' value='Submit' />
        </form>
        { errorsDoExist && <ErrorList /> }
        <BackButton />
        {/* <BackButton whatToDo={resetSkuTypeFormFlags}/> */}
      </article>
     )
    } else {
      return (
        <HeadingBuilder
          Size = 'h3'
          headingText = {t('global.notSignedInMessage')}
          // Not Authorized - Please Sign in or Sign up
        />
      )
    }
}

export default PriceEditForm;