import React from 'react';
import TextToSpeech from './TextToSpeech';
import * as Constants from '../constants';
import { useStyles } from "../hooks/useStyles";
import { usePhrasing, useFormats } from "../hooks/useSkuUtils";

export const ShowAlert = ({
  showAlert  = false,
  alertMsg   = "",
  alertAudio = "",
}) => {

  const { tippyUl } = useStyles();
  const { getAudioText } = usePhrasing();
  const { chgTippySepsToList } = useFormats();

  const alertAudioText = getAudioText({originalText: (alertAudio || alertMsg)})
    
  return (
    <small>
      {
        showAlert
        ? <div className="container mt-2 px-3">
            { alertAudioText.length > Constants.SHOW_AUDIO_UPON_MIN_CHARS  // 50
              ? <>
                  {alertMsg.indexOf(Constants.TIPPY_SEPARATOR) > 0
                    ? <ul style={tippyUl}>
                        { chgTippySepsToList({originalText: alertMsg}) }
                      </ul>
                    : alertMsg
                  }
                  <TextToSpeech text={alertAudioText} />
                  <br/>
                </>
              : <>{alertMsg}</>
              // The MIN_CHARS test means that short tippy text with ' //' like:
              // '! // The quick brown fox jumps over the lazy dog.'
              // will not get the 'chgTippySepsToList' treatment since it is not
              // longer than 50 characters.
            }
          </div>
        : null
      }
    </small>
  )
}

export default ShowAlert;